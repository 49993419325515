import axios from '@/utils/request'

let Api = {
	jobList: "/post/list",
	jobAdd: "/post/",
	jobEdit: "/post/",
	jobDetail: "/post/",
	jobDeletePc: "/post/",
	jobStatus: "/post/changeState/",
	studentAdd: "/post/postAddStudent",
	interviewMess: "/post/site/interview",
	interviewResult: "/post/signUpAdmitOrReject",
	exportJob: "/post/export.xlsx",
	downResume: "/post/download/",
	studentSignUpCancel:"/post/signUpCancel",
	workingScheduleUpload:"/post/workingScheduleUpload",
	schoollistAll:"/school/listAll"
}
//校区列表
export function schoollistAllPc(params) {
	return axios({
		method: 'get',
		url: Api.schoollistAll,
		params: params
	})
}
//岗位导出
export function jobExport(params) {
	return axios({
		method: 'get',
		url: Api.exportJob,
		params: params,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
	})
}
// 岗位列表
export function getJobList(params) {
	return axios({
		method: 'get',
		url: Api.jobList,
		params: params
	})
}
//新增岗位
export function addJob(params) {
	return axios({
		method: 'post',
		url: Api.jobAdd,
		data: params
	})
}
//岗位编辑
export function editJob(params, id) {
	return axios({
		method: 'put',
		url: Api.jobEdit + id,
		data: params
	})
}
//岗位详情
export function getJobDetail(id) {
	return axios({
		method: 'get',
		url: Api.jobDetail + id
	})
}
//岗位删除
export function jobDelete(id) {
	return axios({
		method: 'delete',
		url: Api.jobDeletePc + id
	})
}
//修改岗位状态
export function getJobStatus(params, id) {
	return axios({
		method: 'put',
		url: Api.jobStatus + id,
		params: params
	})
}
//添加学生
export function addStudent(params) {
	return axios({
		method: 'post',
		url: Api.studentAdd,
		data: params
	})
}
//发送面试消息
export function messInterview(params) {
	return axios({
		method: 'post',
		url: Api.interviewMess,
		data: params
	})
}
//岗位录用/拒绝
export function resultInterview(params) {
	return axios({
		method: 'post',
		url: Api.interviewResult,
		data: params
	})
}
//下载全部简历
export function resumeDown(id) {
	return axios({
		method: 'get',
		url: Api.downResume+id,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
	})
}
//学生岗位取消报名
export function studentSignUpPcCancel(params) {
	return axios({
		method: 'post',
		url: Api.studentSignUpCancel,
		data: params
	})
}
//上传排班表
export function workingScheduleUploadPc(params) {
	return axios({
		method: 'post',
		url: Api.workingScheduleUpload,
		data: params
	})
}