<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="jobDetail">
		<div class="jobDetail-desc">
			<span class="jobDetail-desc-title">{{jobDetail.name}}</span>
			<span class="jobDetail-desc-content">{{jobDetail.details}}</span>
		</div>
		<div class="jobDetail-title">
			<span>详细信息</span>
			<img src="@/assets/images/mobile/student/status0.png" alt="" v-if="!jobDetail.signUpStatus&&jobDetail.state==1">
			<img src="@/assets/images/mobile/student/status1.png" alt="" v-if="jobDetail.signUpStatus=='REGISTERING'">
			<img v-if="(!jobDetail.signUpStatus || jobDetail.signUpStatus == '')&&jobDetail.state!==1"
								:src="require(`@/assets/images/mobile/student/status4.png`)" alt=""
								class="top-title-status">
			<img src="@/assets/images/mobile/student/status3.png" alt="" v-if="jobDetail.signUpStatus=='CANCEL'">
			<img src="@/assets/images/mobile/student/status2.png" alt=""
				v-if="jobDetail.signUpStatus=='ADMISSION'&&(jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME')">
		</div>
		<div class="jobDetail-list">
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon6.png" alt="">
				<span class="list-item-label">时间：</span>
				<span class="list-item-val">{{jobDetail.postTime}}</span>
				<!-- <span
					class="list-item-val">{{`${dateFormat(jobDetail.starTime)} 至${dateFormat(jobDetail.endTime)?' '+dateFormat(jobDetail.endTime):'今'}`}}</span> -->
			</div>
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon7.png" alt="">
				<span class="list-item-label">地址：</span>
				<span class="list-item-val">{{jobDetail.address}}</span>
			</div>
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon8.png" alt="">
				<span class="list-item-label">类型：</span>
				<span class="list-item-val">{{jobDetail.postTypeName}}</span>
			</div>
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon9.png" alt="">
				<span class="list-item-label">可报名人数：</span>
				<span class="list-item-val">{{jobDetail.maxPeo}}人</span>
			</div>
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon9.png" alt="">
				<span class="list-item-label">在岗人数：</span>
				<span class="list-item-val">{{jobDetail.admittedNum}}人</span>
			</div>
			<div class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon10.png" alt="">
				<span class="list-item-label">工资：</span>
				<span class="list-item-val">{{jobDetail.salary}}{{jobDetail.salaryUnitName}}</span>
			</div>
			<div v-if="jobDetail.postType == 'SO_FIX_TUTOR'||jobDetail.postType == 'SO_PART_TIME'"
				class="jobDetail-list-item">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon13.png" alt="">
				<span class="list-item-label">岗位要求：<span class="list-item-val">{{jobDetail.postDuty}}</span></span>
			</div>
			<div class="jobDetail-list-item" v-if="jobDetail.contact">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon11.png" alt="">
				<span class="list-item-label">联系人：</span>
				<span class="list-item-val">{{jobDetail.contact}}</span>
			</div>
			<div class="jobDetail-list-item" v-if="jobDetail.contactPhone">
				<img class="list-item-icon" src="@/assets/images/mobile/student/icon12.png" alt="">
				<span class="list-item-label">联系方式：</span>
				<span class="list-item-val">{{jobDetail.contactPhone}}</span>
			</div>
		</div>
		<div class="jobDetail-note">
			注：学生在报名勤工项目后可以取消报名，但所有岗位累计取消报名次数不能超过5次，超过5次将无法取消报名，取消慎重！
			<!-- 注：学生在报名后20个小时内可以取消报名，20个小时后不得随意取消报名，否则将加入黑名单 -->
		</div>
		<div v-if="jobDetail.workingSchedule">
			<!-- <div v-if="(jobDetail.postType=='S_FIX'||jobDetail.postType=='S_TEMP'||jobDetail.postType=='C_FIX')&&jobDetail.workingSchedule"> -->
			<div class="jobDetail-title">
				<span>排班表</span>
			</div>
			<div class="jobDetail-xls">
				<div class="jobDetail-xls-left">
					<img src="@/assets/images/mobile/student/jpg.png" alt="">
					<span>排班表.jpg</span>
				</div>
				<div @click="handleFn('preview')" class="jobDetail-xls-right">
					查看
				</div>
			</div>
		</div>
		<!-- <div > -->
		<div v-if="jobDetail.studentSignUp&&jobDetail.studentSignUp.workingHours&&jobDetail.studentSignUp.workingHours.length>0&&jobDetail.signUpStatus!=='CANCEL'">
			<!-- <div v-if="jobDetail.postType=='S_FIX'||jobDetail.postType=='S_TEMP'||jobDetail.postType=='C_FIX'"> -->
			<div class="jobDetail-title">
				<span>工时进度信息</span>
				<!-- <span class="jobDetail-title-hour" v-if="jobDetail.workingHour">获得工时：{{jobDetail.workingHour}}h</span> -->
			</div>
			<div v-for="(ite,ind) in jobDetail.studentSignUp.workingHours" :key="ind" style="margin-bottom:0.08rem">
			<div class="jobhours-box" v-if="ite.state!=='REJECT'">
				<div class="jobhours-month" v-if="ite.workingHourTime">{{ite.workingHourTime}}月</div>
				<div class="jobDetail-steps">
					<div class="steps-item-top">
						<span v-for="(item,index) in (ite.workingHourProgress.length - 1)" :key="index"
							class="steps-item-line" :class="{'steps-line-active': ite.workingStep >= index}"></span>
						<div class="steps-circle-box">
							<img v-for="(item,index) in ite.workingHourProgress" :key="index"
								class="steps-circle-icon"
								:src="item.happened&&ite.workingStep + 1 <= index?activeStep:item.happened&&ite.workingStep >= index?require(`@/assets/images/mobile/common/step${index + 1}.png`):defaultStep"
								alt="">
						</div>
					</div>
					<div class="steps-item-bottom">
						<div class="item-bottom-item" v-for="(item,index) in ite.workingHourProgress" :key="index">
							<span
								:class="{'bottom-title-active': item.happened&&ite.workingStep + 1 <= index, 'bottom-title-history': item.happened&&ite.workingStep >= index}"
								class="item-bottom-title">{{item.name}}</span>
							<span
								:class="{'bottom-time-active': item.happened&&ite.workingStep + 1 <= index, 'bottom-time-history': item.happened&&ite.workingStep >= index}"
								class="item-bottom-time">{{item.date|dateFilter}}</span>
						</div>
					</div>
				</div>
				<div class="jobDetail-title-hour" v-if="ite.workTime">获得工时：{{ite.workTime}}h</div>
			</div>
			<div class="jobhours-bthbox" v-if="ite.state==='SUBMIT'">
				<div class="jobDetail-confirm btn" @click="handleWorkHourdClick('accept',ite)">
					确认工时
				</div>
				<div class="jobDetail-cancel btn" @click="handleWorkHourdClick('reject',ite)">
					驳回工时
				</div>
			</div>
			</div>
		</div>
		<div v-if="jobDetail.signUpProgress&&jobDetail.signUpProgress.length>0&&jobDetail.signUpStatus!=='CANCEL'">
			<!-- <div v-if="(jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME')&&jobDetail.signUpProgress"> -->
			<div class="jobDetail-title">
				<span>岗位进度信息</span>
			</div>
			<div class="jobDetail-steps">
				<div class="steps-item-top">
					<span v-for="(item,index) in (jobDetail.signUpProgress.length - 1)" :key="index"
						class="steps-item-line" :class="{'steps-line-active': jobDetail.signUpStep >= index}"></span>
					<div class="steps-circle-box">
						<img v-for="(item,index) in jobDetail.signUpProgress" :key="index" class="steps-circle-icon"
							:src="item.happened&&jobDetail.signUpStep + 1 <= index?activeStep:item.happened&&jobDetail.signUpStep >= index?require(`@/assets/images/mobile/common/step${index + 1}.png`):defaultStep"
							alt="">
					</div>
				</div>
				<div class="steps-item-bottom">
					<div class="item-bottom-item" v-for="(item,index) in jobDetail.signUpProgress" :key="index">
						<span
							:class="{'bottom-title-active': item.happened&&jobDetail.signUpStep + 1 <= index, 'bottom-title-history': item.happened&&jobDetail.signUpStep >= index}"
							class="item-bottom-title">{{item.name}}</span>
						<span
							:class="{'bottom-time-active': item.happened&&jobDetail.signUpStep + 1 <= index, 'bottom-time-history': item.happened&&jobDetail.signUpStep >= index}"
							class="item-bottom-time">{{item.date|dateFilter}}</span>
					</div>
				</div>
			</div>
		</div>
		<div @click="handleFn('signup')" class="jobDetail-confirm"
			v-if="(!jobDetail.signUpStatus || jobDetail.signUpStatus == 'CANCEL')&&(jobDetail.postType == 'S_TEMP' || jobDetail.postType == 'S_FIX')&&jobDetail.isSelfCancel != false&&showBtn&&jobDetail.state==1">
			立即报名
		</div>
		<div @click="handleFn('signup')" class="jobDetail-confirm"
			v-if="(!jobDetail.signUpStatus || jobDetail.signUpStatus == 'CANCEL')&&jobDetail.postType != 'S_TEMP'&&jobDetail.postType != 'S_FIX'&&showBtn&&jobDetail.state==1">
			立即报名
		</div>
		<div v-if="jobDetail.studentSignUp&&jobDetail.studentSignUp.canCancel==true">
			<!-- <div @click="handleFn('openCode')" class="jobDetail-confirm"
				v-if="jobDetail.qrCode&&(jobDetail.postType=='S_FIX'||jobDetail.postType=='S_TEMP')&&showBtn&&isShowCancel">
				二维码签到/签退
			</div> -->
			<div @click="handleFn('resume')" class="jobDetail-confirm" v-if="jobDetail.postType=='SO_FIX_TUTOR'||jobDetail.postType=='SO_PART_TIME'">
				{{jobDetail.resume?'简历已上传':'简历未上传'}}
			</div>
			<div class="jobDetail-cancel" @click="handleFn('cancel')" v-if="isShowCancel">
				取消报名
			</div>
		</div>
		<!-- <div v-if="showCode" class="jobDetail-code">
			<div class="jobDetail-code-box">
				<div class="jobDetail-code-inner">
					<img :src="jobDetail.qrCode" alt="">
					<span>二维码主要用于参加的校内岗位的签到签退</span>
				</div>
				<img @click="handleFn('closeCode')" class="jobDetail-code-close"
					src="@/assets/images/mobile/student/close.png" alt="">
			</div>
		</div> -->
	</div>
</template>
<script>
	import {
		ImagePreview
	} from 'vant';
	import {
		getJobDetail,
	} from '@/api/pc/index'
	import {
		studentSignUpMb,
		studentSignUpMbCancel,
		confirmWorkingHourMb
	} from "@/api/mobile/activity";
	export default {
		data() {
			return {
				jobDetail: {
					title: "一二三年级陪读",
					content: "我们旨在基于信任，通过技术和服务，发展我们的企业合作伙伴成长，推动他们的业务增长。我们追求成为100万家企业的首选合作伙伴，建立基于信任的合作。"
				},
				activeStep: require("@/assets/images/mobile/common/checked.png"),
				defaultStep: require("@/assets/images/mobile/common/uncheck.png"),
				showCode: false,
				detailId: "",
				showBtn: false,
				isShowCancel: true
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.detailId = this.$route.query.id
				this.getWorkDetail()
			}
		},
		filters: {
			dateFilter(date) {
				if (date) {
					return date.slice(5, -3)
				} else {
					return ""
				}
			}
		},
		methods: {
			//点击确认驳回工时
			handleWorkHourdClick(type,item){
				let params = {}
				if(type==='accept'){
					params = {
						accept:1,
						workingHoursId:item.id
					}
				}else{
					params = {
						accept:0,
						workingHoursId:item.id
					}
				}
				confirmWorkingHourMb(params).then(res=>{
					if(res.code == 0){
						if(type==='accept'){
							this.$toast("工时确认成功！")
						}else{
							this.$toast("工时驳回成功！")
						}
						this.getWorkDetail()
					}
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					if(this.jobDetail.postType == 'C_FIX') {
						let newDate = new Date(intDate)
						let year = newDate.getFullYear()
						let month = newDate.getMonth() + 1
						return `${year}-${month}`
					} else {
						return intDate.replace(":00", "")
					}
				} else {
					return ""
				}
			},
			getWorkDetail() {
				this.showBtn = false
				getJobDetail(this.detailId).then(res => {
					if (res.code == 0) {
						// let worksArr = [
						// 	{
						// 	  happened: false,
						// 	  name: "老师提交",
						// 	  type: "WORKING_HOUR_SUBMIT"
						// 	},
						// 	{
						// 	  happened: false,
						// 	  name: "学生确认",
						// 	  type: "WORKING_HOUR_CONFIRM"
						// 	},
						// 	{
						// 	  happened: false,
						// 	  name: "干部审核",
						// 	  type: "WORKING_HOUR_VERIFY"
						// 	},
						// 	{
						// 	  happened: false,
						// 	  name: "计财处发放",
						// 	  type: "WORKING_HOUR_SETTLEMENT"
						// 	},
						// ]
						// res.data.workingHourProgress = worksArr
						if(res.data.studentSignUp&&res.data.studentSignUp.workingHours&&res.data.studentSignUp.workingHours.length>0){

							let workingHours = res.data.studentSignUp.workingHours
							workingHours.forEach(item=>{
								if(item.workingHourProgress&&item.workingHourProgress.length>0){
									let stepArr = item.workingHourProgress.filter(ite => ite.happened == true)
									if (stepArr && stepArr.length >= 2) {
										this.$set(item, "workingStep", (stepArr.length - 2))
									} else if (stepArr && stepArr.length == 1) {
										this.$set(item, "workingStep", -1)
									}
								}
							})
						}
						// if (res.data.workingHourProgress && res.data.workingHourProgress.length > 0) {
						// 	let stepArr = res.data.workingHourProgress.filter(item => item.happened == true)
						// 	if (stepArr && stepArr.length >= 2) {
						// 		this.$set(res.data, "workingStep", (stepArr.length - 2))
						// 	} else if (stepArr && stepArr.length == 1) {
						// 		this.$set(res.data, "workingStep", -1)
						// 	}
						// }
						if (res.data.signUpProgress && res.data.signUpProgress.length > 0) {
							let stepArr = res.data.signUpProgress.filter(item => item.happened == true)
							if (stepArr && stepArr.length >= 2) {
								this.$set(res.data, "signUpStep", (stepArr.length - 2))
							} else if (stepArr && stepArr.length == 1) {
								this.$set(res.data, "signUpStep", -1)
							}
						}
						this.jobDetail = res.data
						this.isShowCancelBtn(res.data.endTime)
						this.showBtn = true
					} else {
						this.showBtn = false
					}
				})
			},
			isShowCancelBtn(endTime) {
				if (endTime) {
					let nowDate = new Date().getTime()
					let newEndtime = new Date(endTime).getTime()
					console.log(nowDate, newEndtime)
					if (nowDate > newEndtime) {
						this.isShowCancel = false
					} else {
						this.isShowCancel = true
					}
				}
			},
			handleFn(type) {
				switch (type) {
					case "preview":
						ImagePreview([this.jobDetail.workingScheduleUrl])
						// ImagePreview(['https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg']);
						break;
					case "openCode":

						this.showCode = true

						break;
					case "closeCode":
						this.showCode = false
						break;
					case "signup":
						this.handleSignUp()
						break;
					case "resume":
						if(this.jobDetail.resume){
							this.$router.push({
								path: `/mb/mine/resume?postId=${this.jobDetail.id}&&resume=${this.jobDetail.resume}`
							})
						}else{
							this.$router.push({
								path: `/mb/mine/resume?postId=${this.jobDetail.id}`
							})
						}
						
						break;
					case "cancel":
						this.handleSignUpCancel()
						break;
				}

			},
			handleSignUp() {
				if (this.jobDetail.workingHourStatus == 1) {
					this.$toast("该岗位已结束无法报名！")
					return
				}
				studentSignUpMb({
					id: this.detailId
				}).then(res => {
					if (res.code == 0) {
						this.getWorkDetail()
					}
				})
			},
			handleSignUpCancel() {
				studentSignUpMbCancel({
					id: this.jobDetail.signUpId
				}).then(res => {
					if (res.code == 0) {
						this.getWorkDetail()
					}
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.jobDetail {
		display: flex;
		flex-direction: column;
		.jobhours-bthbox{
			padding:0 0.28rem;
			margin-top:0.2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.btn{
				width:3.36rem;
				margin:0!important;
			}
		}
		.jobDetail-desc {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0.28rem 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.jobDetail-desc-title {
				font-size: 0.36rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.5rem;
				color: #303133;
				margin-bottom: 0.2rem;
			}

			.jobDetail-desc-content {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.40rem;
				color: #606266;
			}
		}
		.jobhours-box{
			background:#FFFFFF;
			position:relative;
			padding:0.4rem 0 0.2rem 0;
			.jobhours-month{
				color:#FFFFFF;
				box-sizing: border-box;
				min-width:0.72rem;
				font-size:0.24rem;
				font-weight: bold;
				position:absolute;
				padding:0.04rem 0.12rem 0.04rem 0.08rem;
				background:#FFAE00;
				border-radius: 0px 20px 20px 0px;
				top:0;
				left:0;
			}
		}
		.jobDetail-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0.28rem 0;

			span {
				font-size: 0.32rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.44rem;
				color: #303133;
				padding-left: 0.2rem;
				border-left: 0.08rem solid #4392FA;
			}

			img {
				width: 0.96rem;
				height: 0.34rem;
				margin-right: 0.28rem;
			}

			.jobDetail-title-hour {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #15D2B7;
				border-left: 0;
				margin-right: 0.28rem;
			}
		}
		.jobDetail-note{
			font-size:0.24rem;
			color:#EF2828;
			padding:0 0.4rem 0 0.28rem;
			margin-top:0.2rem;
		}
		.jobDetail-list {
			display: flex;
			flex-direction: column;
			background: #FFFFFF;
			padding: 0 0.28rem;
			box-sizing: border-box;
			margin-top: 0.16rem;

			.jobDetail-list-item {
				display: flex;
				align-items: flex-start;
				// min-height: 0.94rem;
				padding: 0.32rem 0;
				border-bottom: 0.02rem solid #DCDFE6;

				.list-item-icon {
					width: 0.32rem;
					height: 0.32rem;
					margin-right: 0.16rem;
				}

				.list-item-label {
					min-width: 0.8rem;
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					color: #909399;
				}

				.list-item-val {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					// line-height: 0.4rem;
					color: #303133;
				}
			}
		}

		.jobDetail-list :last-child {
			border: 0;
		}

		.jobDetail-xls {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #FFFFFF;
			box-shadow: 0rem 0.04rem 0.16rem rgba(220, 223, 230, 0.2000);
			padding: 0.2rem 0.28rem;
			box-sizing: border-box;

			.jobDetail-xls-left {
				display: flex;
				align-items: center;

				img {
					width: 0.7rem;
					height: 0.8rem;
				}

				span {
					font-size: 0.28rem;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 0.4rem;
					color: #303133;
					margin-left: 0.2rem;
				}
			}

			.jobDetail-xls-right {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #4392FA;
			}
		}
		.jobDetail-title-hour {
			text-align: center;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 0.4rem;
			color: #15D2B7;
			border-left: 0;
			// margin-right: 0.28rem;
			margin-top:0.08rem;
		}
		.jobDetail-steps {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0 0.38rem;
			box-sizing: border-box;

			.steps-item-top {
				width: 100%;
				height: 0.28rem;
				padding: 0 0.42rem;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				.steps-item-line {
					flex: 1;
					display: inline-block;
					border: 0.02rem dashed #DCDFE6;
				}

				.steps-circle-box {
					width: 100%;
					height: 0.28rem;
					padding: 0 0.38rem;
					box-sizing: border-box;
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.steps-circle-icon {
						width: 0.28rem;
						height: 0.28rem;
						border-radius: 50%;
					}
				}

				.steps-line-active {
					border-color: #4392FA;
				}

				.steps-item-circle {
					width: 0.28rem;
					height: 0.28rem;
					// border: 0.02rem solid #B4BCC6;
					border-radius: 50%;
				}
			}

			.steps-item-bottom {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.item-bottom-item {
					min-width: 1.2rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.item-bottom-title {
						font-size: 0.24rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #909399;
						height: 0.34rem;
						line-height: 0.34rem;
						margin: 0.16rem 0 0.04rem 0;
					}

					.item-bottom-time {
						// width:1.3rem;
						font-size: 0.2rem;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #909399;
						height: 0.28rem;
						line-height: 0.28rem;
					}

					.bottom-title-history,
					.bottom-time-history {
						color: #606266;
					}

					.bottom-title-active,
					.bottom-time-active {
						color: #4392FA;
					}
				}
			}
		}

		.jobDetail-confirm {
			width: 6.94rem;
			height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
			background: #4392FA;
			margin: 0 auto;
			margin-top: 0.4rem;
		}

		.jobDetail-cancel {
			width: 6.94rem;
			height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #4392FA;
			border: 0.02rem solid #4392FA;
			margin: 0.2rem auto;
		}

		.jobDetail-code {
			width: 100%;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;

			.jobDetail-code-box {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.jobDetail-code-inner {
					width: 6.22rem;
					height: 6.76rem;
					background: #FFFFFF;
					box-shadow: 0rem 0.08rem 0.4rem rgba(220, 223, 230, 0.2000);
					opacity: 1;
					border-radius: 0.28rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 0.64rem 0.64rem;
					box-sizing: border-box;

					img {
						width: 4.94rem;
						height: 4.94rem;
						margin-bottom: 0.2rem;
					}

					span {
						font-size: 0.24rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.34rem;
						color: #909399;
					}
				}

				.jobDetail-code-close {
					width: 0.8rem;
					height: 0.8rem;
					margin-top: 0.28rem;
				}
			}
		}
	}
</style>
